import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Role } from '../models/role';
import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUser();
    if (currentUser) {
      if (route.data.roles && !currentUser.perfis.find(x => route.data.roles.includes(x))) {
        if (route.data.roles.includes(Role.Admin) && currentUser.is_admin) {
          return true;
        }
        if (route.routeConfig.path == 'adm/manager-user/:userId' && route.params.userId == currentUser.id) {
          return true;
        }
        
        if (route.routeConfig.path === 'adm/list-pedido' || route.routeConfig.path.indexOf("adm/relatorios") !== -1) {
          return true;
        }

        return this.router.parseUrl(`/error-404?returnUrl=${state.url}`);
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    return this.router.parseUrl(`/account/login?returnUrl=${state.url}`);
  }
}
