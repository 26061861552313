import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbPaginationModule, NgbModalModule, NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxEditorModule } from 'ngx-editor';
import { InputFormModule } from 'src/app/components/input-form/input-form.module';
import { LayoutsModule } from 'src/app/layouts/layouts.module';
import { EmpresaCentroCustoComponent } from './empresaCentroCusto.component';
import { InputFormComponent } from 'src/app/components/input-form/input-form.component';
import { EmpresaCentroCustoRoutingModule } from './empresaCentroCusto-routing.module';
import { UIModule } from 'src/app/shared/ui/ui.module';
import { VerificaSolicitacaoComponent } from 'src/app/pages/empresaCentroCusto/verificaSolicitacao/verificaSolicitacao.component';

@NgModule({
  declarations: [
    EmpresaCentroCustoComponent,
    VerificaSolicitacaoComponent
  ],
  entryComponents: [InputFormComponent, VerificaSolicitacaoComponent],
  imports: [
    CommonModule,
    FormsModule,
    EmpresaCentroCustoRoutingModule,
    UIModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModalModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    NgbDropdownModule,
    NgbCollapseModule,
    InputFormModule,
    NgxEditorModule,
    LayoutsModule
  ]
})
export class EmpresaCentroCustoModule { }
