import { Component } from '@angular/core';

@Component({
  selector: 'app-ubold',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
    // TODO: remove in the next release
    this.clearCookies();
  }

  // TODO: remove in the next release
  clearCookies() {
    document.cookie = 'currentCliente=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'currentCentro=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'currentUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}
