import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

import { environment } from 'src/environments/environment';
import { LocalStorage } from '../helpers/local-storage';
import { LoggedUser } from '../models/logged-user.models';
import { CentroCustoService } from './centro-custo.service';
import { ClienteService } from './cliente.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  user: LoggedUser;

  constructor(
    private http: HttpClient,
    private _centroCusto: CentroCustoService,
    private _cliente: ClienteService,
  ) { }

  public currentUser(): LoggedUser {
    if (!this.user) {
      this.user = LocalStorage.getItem('currentUser');
    }
    return this.user;
  }

  public isComprador() {
    let comprador = false;
    if (this.user) {
      this.user = LocalStorage.getItem('currentUser');
      this.user.perfis.forEach(item => {
        if (item === 2) {
          return comprador = true;
        }else{
          comprador = false;
        }
      });
    }
    return comprador;
  }

  public isVendedor() {
    let vendedor = false;
    if (this.user) {
      this.user = LocalStorage.getItem('currentUser');
      this.user.perfis.forEach(item => {
        if (item === 3) {
          return vendedor = true;
        }else{
          vendedor = false;
        }
      });
    }
    return vendedor;
  }

  public isGerente() {
    let gerente = false;
    if (!this.user) {
      this.user = LocalStorage.getItem('currentUser');
      this.user.perfis.forEach(item => {
        if (item === 4) {
          return gerente = true;
        }else{
          gerente = false;
        }
      });
    }
    return gerente;
  }

  async login(email: string, senha: string) {
    const token = await this.http
      .post<any>(`${environment.apiUrl}/login`, { email, senha })
      .toPromise();

    if (token) {
      this.user = jwt_decode(token);
      this.user.token = token;
      // console.log("currentUser", this.user);
      LocalStorage.setItem('currentUser', this.user);

      return this.user;
    } else {
      throw new Error('Não foi possível concluir a ação por favor tente novamente.');
    }
  }

  logout() {
    LocalStorage.clear();

    this._cliente.cliente = null;
    this._centroCusto.centro = null;
    this.user = null;
  }
}
