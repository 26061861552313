import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verificaSolicitacao',
  templateUrl: './verificaSolicitacao.component.html',
  styleUrls: ['./verificaSolicitacao.component.scss']
})
export class VerificaSolicitacaoComponent implements OnInit {
  modal: any;
  dia_inicial_solicitacao_pedido: number;
  dia_final_solicitacao_pedido: number;

  constructor(public activeModal: NgbActiveModal) { }

  async ngOnInit() { }
}
