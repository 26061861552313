const sellersData = [
    {
        image: 'assets/images/users/user-2.jpg',
        name: 'Paul J. Friend',
        store: 'Homovee',
        product: 128,
        balance: '$128,250',
        date: '07/07/2018',
        revenue: '$258.26k'
    },
    {
        image: 'assets/images/users/user-3.jpg',
        name: 'Bryan J. Luellen',
        store: 'Execucy',
        product: 9,
        balance: '$78,410',
        date: '09/12/2018',
        revenue: '$152.3k'
    },
    {
        image: 'assets/images/users/user-4.jpg',
        name: 'Kathryn S. Collier',
        store: 'Epiloo',
        product: 78,
        balance: '$89,458',
        date: '06/30/2018',
        revenue: '$178.6k'
    },
    {
        image: 'assets/images/users/user-1.jpg',
        name: 'Timothy Kauper',
        store: 'Uberer',
        product: 847,
        balance: '$258,125',
        date: '09/08/2018',
        revenue: '$368.2k'
    },
    {
        image: 'assets/images/users/user-5.jpg',
        name: 'Zara Raws',
        store: 'Symic',
        product: 235,
        balance: '$56,210',
        date: '09/05/2018',
        revenue: '$258.26k'
    },
    {
        image: 'assets/images/users/user-6.jpg',
        name: ' Annette P. Kelsch',
        store: 'Insulore',
        product: 485,
        balance: '$330,251',
        date: '09/05/2018',
        revenue: '$597.8k'
    },
    {
        image: 'assets/images/users/user-7.jpg',
        name: 'Jenny C. Gero',
        store: 'Susadmin',
        product: 38,
        balance: '$12,000',
        date: '08/02/2018',
        revenue: '$29.3k'
    },
    {
        image: 'assets/images/users/user-8.jpg',
        name: 'Edward Roseby',
        store: 'Hyperill',
        product: 77,
        balance: '$45,216',
        date: '08/23/2018',
        revenue: '$48.6k'
    },
    {
        image: 'assets/images/users/user-9.jpg',
        name: 'Anna Ciantar',
        store: 'Vicedel',
        product: 347,
        balance: '$7,815',
        date: '05/06/2018',
        revenue: '$12.1k'
    },
    {
        image: 'assets/images/users/user-10.jpg',
        name: 'Dean Smithies',
        store: 'Circumous',
        product: 506,
        balance: '$68,143',
        date: '04/09/2018',
        revenue: '$78.2k'
    }
];

export { sellersData };
