import { Injectable } from '@angular/core';
import { LocalStorage } from '../helpers/local-storage';
import { LoggedUser } from '../models/logged-user.models';
import { ProfileRBAC, RBAC } from '../models/rbac';
import { EnumPerfilRBAC } from '../enums/perfil-rbac';

@Injectable({ providedIn: 'root' })
export class UserACLService {
  private user: LoggedUser;

  constructor() {
    this.currentUser();
  }

  public currentUser(): LoggedUser {
    if (!this.user) {
      this.user = LocalStorage.getItem('currentUser');
    }
    return this.user;
  }

  /**
   * @description Retorna um objeto  RBAC (Regra Baseada Acesso Controle)
   * @returns
   *
   * ```ts
   *  {
   *    is_admin:  boolean,
   *    is_bloquear_pedido: boolean,
   *    visualiza_preco_podutos: boolean,
   *    isComprador: boolean;
   *    isGerente: boolean;
   *    isVendedor: boolean;
   * }
   * ```
   */
  get can(): RBAC {

    return {
      is_admin: this.user.is_admin,
      is_bloquear_pedido: this.user.is_bloquear_pedido,
      visualiza_preco_podutos: this.user.visualiza_preco_podutos,
      isComprador: this._profileRBAC().comprador,
      isGerente: this._profileRBAC().gerente,
      isVendedor: this._profileRBAC().vendedor
    };
  }

  /**
 * @description Retorna um objeto  ProfileRBAC  Perfil do usuario com (Regra Baseada Acesso Controle)
 * @returns
 *
 * ```ts
 *  {
 *    vendedor: boolean;
 *    comprador: boolean;
 *    gerente: boolean;
 * }
 * ```
 */
  private _profileRBAC(): ProfileRBAC {
    const profile: ProfileRBAC = {
      vendedor: false,
      comprador: false,
      gerente: false
    };
    if (this.user) {
      this.user.perfis.forEach(item => {
        switch (item) {
          case EnumPerfilRBAC.Gerente:
            profile.gerente = true;
            break;
          case EnumPerfilRBAC.Comprador:
            profile.comprador = true;
            break;
          case EnumPerfilRBAC.Vendedor:
            profile.vendedor = true;
            break;
          default:
            return item;
        }
      });
    }
    return profile;
  }
}
