import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMaskModule } from 'ngx-mask';

import { EcommerceModule } from '../pages-template/ecommerce/ecommerce.module';
import { EcommercedashboardComponent } from '../pages-template/ecommerce/ecommercedashboard/ecommercedashboard.component';
import { UIModule } from '../shared/ui/ui.module';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TopbarComponent } from './topbar/topbar.component';

@NgModule({
  declarations: [LayoutComponent, TopbarComponent, FooterComponent, NavbarComponent],
  exports: [LayoutComponent],
  entryComponents: [EcommercedashboardComponent],
  imports: [
    CommonModule,
    RouterModule,
    EcommerceModule,

    NgbDropdownModule,
    NgbCollapseModule,
    ClickOutsideModule,
    UIModule,

    FormsModule,
    NgxMaskModule.forRoot()
  ]
})
export class LayoutsModule {}
