import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExtrasRoutingModule } from './extras-routing.module';
import { UIModule } from '../../shared/ui/ui.module';

import { NgbProgressbarModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ngx-lightbox';

import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [ Error404Component, Error500Component],
  imports: [
    CommonModule,
    ExtrasRoutingModule,
    UIModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    LightboxModule
  ]
})
export class ExtrasModule { }
