import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { LocalStorage } from '../helpers/local-storage';
import { CentroCusto } from '../models/centro-custo';
import { RetornoPaginacao } from '../models/retorno-paginacao.models';
import { ListagemUserFilter } from './user.service';

@Injectable({ providedIn: 'root' })
export class CentroCustoService {
 private readonly porPagina = 50;
  centro: CentroCusto;

  constructor(private http: HttpClient) {}

  public current(): CentroCusto {
    if (!this.centro) {
      this.centro = LocalStorage.getItem('currentCentro');
    }
    return this.centro;
  }

  public setCurrent(centro) {
    this.centro = centro;
    LocalStorage.setItem('currentCentro', centro);
  }

  getUserId(cliente_id) {
    return this.http
      .post<any>(`${environment.apiUrl}/centro-custo/listagem`, { cliente_id })
      .toPromise();
  }

  listagem(porPagina: number, pagina: number, filter?: ListagemUserFilter) {
    return this.http
      .post<RetornoPaginacao<CentroCusto>>(
        `${environment.apiUrl}/centro-custo/listagem?total_por_pagina=${porPagina}&pagina=${pagina}`,
        filter
      )
      .toPromise();
  }

  adicionar(user) {
    return this.http.post(`${environment.apiUrl}/centro-custo`, user).toPromise();
  }

  alterar(user) {
    return this.http.put(`${environment.apiUrl}/centro-custo`, user).toPromise();
  }

  obter(id) {
    return this.http
      .get<CentroCusto>(`${environment.apiUrl}/centro-custo/${id}`)
      .toPromise()
      .then(res => {
        if (res.data_fundacao) {
          res.data_fundacao = new Date(res.data_fundacao);
        }
        if (res.data_implantacao) {
          res.data_implantacao = new Date(res.data_implantacao);
        }
        return res;
      });
  }

  excluir(id) {
    return this.http.delete(`${environment.apiUrl}/centro-custo/${id}`).toPromise();
  }
}
