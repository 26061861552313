import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { LocalStorage } from '../helpers/local-storage';
import { CentroCusto } from '../models/centro-custo';
import { Cliente } from '../models/cliente';
import { RetornoPaginacao } from '../models/retorno-paginacao.models';
import { ListagemUserFilter } from './user.service';

@Injectable({ providedIn: 'root' })
export class ClienteService {
 private readonly porPagina = 50;

  cliente: Cliente;

  constructor(private http: HttpClient) {}

  public current(): Cliente {
    if (!this.cliente) {
      this.cliente = LocalStorage.getItem('currentCliente');
    }
    return this.cliente;
  }

  public setCurrent(centro) {
    this.cliente = centro;
    LocalStorage.setItem('currentCliente', centro);
  }

  listagem(porPagina: number, pagina: number) {
    return this.http.post<any>(`${environment.apiUrl}/cliente/listagem?total_por_pagina=${porPagina}&pagina=${pagina}`, {}).toPromise();
  }

  getAll(porPagina: number, pagina: number, filter?: ListagemUserFilter) {
    return this.http
      .post<RetornoPaginacao<CentroCusto>>(`${environment.apiUrl}/cliente/listagem?total_por_pagina=${this.porPagina}&pagina=${pagina}`, filter)
      .toPromise();
  }

  excluir(id) {
    return this.http.delete(`${environment.apiUrl}/cliente/${id}`).toPromise();
  }

  obter(id) {
    return this.http
      .get<any>(`${environment.apiUrl}/cliente/${id}`)
      .toPromise()
      .then(res => {
        if (res.data_fundacao) {
          res.data_fundacao = new Date(res.data_fundacao);
        }
        if (res.data_implantacao) {
          res.data_implantacao = new Date(res.data_implantacao);
        }
        return res;
      });
  }

  adicionar(user) {
    return this.http.post(`${environment.apiUrl}/cliente`, user).toPromise();
  }

  alterar(user) {
    return this.http.put(`${environment.apiUrl}/cliente`, user).toPromise();
  }
}
