import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/user.models';
import { environment } from 'src/environments/environment';
import { RetornoPaginacao } from '../models/retorno-paginacao.models';

@Injectable({ providedIn: 'root' })
export class UserService {
 private readonly porPagina = 50;

  constructor(private http: HttpClient) { }

  getAll(porPagina: number, pagina: number, filter?: ListagemUserFilter) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/usuario/listagem?total_por_pagina=${this.porPagina}&pagina=${pagina}`,
        filter
      )
      .toPromise();
  }

  adicionar(user) {
    return this.http.post(`${environment.apiUrl}/usuario`, user).toPromise();
  }

  alterar(user) {
    return this.http.put(`${environment.apiUrl}/usuario`, user).toPromise();
  }

  obter(id) {
    return this.http.get<User>(`${environment.apiUrl}/usuario/${id}`).toPromise();
  }

  emailConflict(email) {
    return this.http.get<any>(`${environment.apiUrl}/usuario/email?email=${email}`)
  }

  excluir(id) {
    return this.http.delete(`${environment.apiUrl}/usuario/${id}`).toPromise();
  }

  alterarSenha(senha) {
    return this.http.put(`${environment.apiUrl}/usuario/senha`, senha).toPromise();
  }

  alterarFoto(file) {
    console.log(file);
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put(`${environment.apiUrl}/usuario/foto`, formData).toPromise();
  }

  alterarFotoId(id, file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put(`${environment.apiUrl}/usuario/foto-usuario/${id}`, formData).toPromise();
  }
}

export interface ListagemUserFilter {
  email?: string;
  telefone?: string;
  celular?: string;
  nome?: string;
  documento?: string;
  cliente_id?: any;
  centro_custo_id?: any;
}
