import { CotaProduto } from './cota-produto';
import { Produto } from './produto';

export class Carrinho {
  id?: number;
  data_criacao: Date;
  itens: ItemCarrinho[];
}

export class ItemCarrinho {
  id?: number;
  produto_id: number;
  nome?: string;
  nome_produto: string;
  valor: number;
  quantidade: number;
  data_criacao?: string;
  cota?:CotaProduto;
  produto: Produto
}
