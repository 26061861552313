import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { EmpresaCentroCustoComponent } from './pages/empresaCentroCusto/empresaCentroCusto.component';
import { Error404Component } from './pages/extras/error404/error404.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  { path: 'extras', loadChildren: () => import('./pages/extras/extras.module').then(m => m.ExtrasModule) },
  {
    path: 'empresa-centro',
    // component: ac,
    loadChildren: () => import('./pages/empresaCentroCusto/empresaCentroCusto.module').then(m => m.EmpresaCentroCustoModule),
  },


  { path: '**', component: Error404Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
