import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ClienteService } from 'src/app/core/services/cliente.service';
import { CentroCusto } from '../../core/models/centro-custo';
import { User } from '../../core/models/user.models';
import { CentroCustoService } from '../../core/services/centro-custo.service';
import { VerificaSolicitacaoComponent } from 'src/app/pages/empresaCentroCusto/verificaSolicitacao/verificaSolicitacao.component';

@Component({
  selector: 'app-empresaCentroCusto',
  templateUrl: './empresaCentroCusto.component.html',
  styleUrls: ['./empresaCentroCusto.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmpresaCentroCustoComponent implements OnInit {
  formGroup: FormGroup;
  user: User;
  showCentro: CentroCusto[];
  allCentro: CentroCusto[] = [];
  allCliente: User[] = [];
  loading = false;
  submitted = false;
  submitting = false;
  error = '';
  modal = false;
  entrar = false;

  constructor(
    private _centroCusto: CentroCustoService,
    private _cliente: ClienteService,
    private _auth: AuthenticationService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    this.user = this._auth.currentUser();
  }

  async ngOnInit() {
    document.body.classList.remove('authentication-bg');
    document.body.classList.remove('authentication-bg-pattern');

    this.loading = true;

    this.formGroup = this.formBuilder.group({
      centro_id: ['', [Validators.required]],
      cliente_id: ['', [Validators.required]]
    });

    if (this.user.is_admin) {
      this.allCliente = await (await this._cliente.listagem(9999, 1)).lista;
      this.allCentro = await (await this._centroCusto.listagem(9999, 1)).lista;
    } else {
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.user.clientes.length; index++) {
        const element = this.user.clientes[index];
        const cliente = await this._cliente.obter(element);
        this.allCliente.push(cliente);
      }

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.user.centros_custo.length; index++) {
        const element = this.user.centros_custo[index];
        this.allCentro.push(await this._centroCusto.obter(element));
      }
    }
    this.loading = false;

    const currentCliente = this._cliente.current();
    const currentCentro = this._centroCusto.current();

    if (!currentCliente && !currentCentro) {
      this.modal = false;
    }

    if (currentCliente) {
      this.f.cliente_id.setValue(currentCliente.id);
      this.change();
    }

    setTimeout(() => {
      if (currentCentro) {
        this.f.centro_id.setValue(currentCentro.id);
      }
    }, 500);
  }

  get f() {
    return this.formGroup.controls;
  }

  change() {
    this.showCentro = [];
    this.f.centro_id.setValue(null);
    this.allCentro.forEach((element, i) => {
      if (element.cliente_id === this.f.cliente_id.value) {
        this.showCentro.push(element);
      }
    });
  }

  async onSubmit() {
    this.submitted = true;
    if (this.formGroup.invalid) {
      return;
    }

    this.submitting = true;
    this.error = '';
    // Object.assign(this.centro, this.userForm.value);
    try {
      if (this.f.centro_id.value) {
        this._centroCusto.setCurrent(this.allCentro.find(x => x.id === this.f.centro_id.value));
      }

      if (this.f.cliente_id.value) {
        this._cliente.setCurrent(this.allCliente.find(x => x.id === this.f.cliente_id.value));
      }

      this.modalService.dismissAll();
      window.location.reload();

      // if (this.modal) {
      //   this.modalService.dismissAll();
      //   window.location.reload();
      // } else {
      //   this.router.navigateByUrl('/list-produtos');
      // }
    } catch (error) {
      this.error = error;
      this.submitting = false;
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  verificaSoliticacao() {
    this._centroCusto.obter(this.formGroup.controls.centro_id.value).then(res => {
      const dateCurrent = new Date().getDate();
      const dia_inicial_solicitacao_pedido = parseInt(res.dia_inicial_solicitacao_pedido)
      const dia_final_solicitacao_pedido = parseInt(res.dia_final_solicitacao_pedido)
      if (dateCurrent < dia_inicial_solicitacao_pedido || dateCurrent > dia_final_solicitacao_pedido) {
        const modal = this.modalService.open(VerificaSolicitacaoComponent);
        modal.componentInstance.modal = true;
        modal.componentInstance.dia_inicial_solicitacao_pedido = dia_inicial_solicitacao_pedido;
        modal.componentInstance.dia_final_solicitacao_pedido = dia_final_solicitacao_pedido;
      }
    });

  }
}
