import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

import { ItemCarrinho } from 'src/app/core/models/carrinho';
import { CarrinhoService } from 'src/app/core/services/carrinho.service';
import { CentroCustoService } from 'src/app/core/services/centro-custo.service';
import { ClienteService } from 'src/app/core/services/cliente.service';
import { EmpresaCentroCustoComponent } from 'src/app/pages/empresaCentroCusto/empresaCentroCusto.component';
import { CentroCusto } from '../../core/models/centro-custo';
import { Cliente } from '../../core/models/cliente';
import { AuthenticationService } from '../../core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserACLService } from 'src/app/core/services/user-acl.service';
import { RBAC } from 'src/app/core/models/rbac';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  carrinhos: Array<ItemCarrinho>;
  openMobileMenu: boolean;
  loading = false;
  user: any;

  currentCliente: Cliente;
  currentCentro: CentroCusto;
  userACL: RBAC;

  constructor(
    private router: Router,
    private _carrinho: CarrinhoService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private _centroCusto: CentroCustoService,
    private _cliente: ClienteService,
    private _user: UserService,
    private _userACL: UserACLService

  ) { }

  ngOnInit() {
    this.userACL  = this._userACL.can;
    this.user = this.authService.currentUser();
    this.currentCliente = this._cliente.current();
    this.currentCentro = this._centroCusto.current();
    this.openMobileMenu = false;
    this.getCountCart();
    this.carrinhos = [];

    if (!this.currentCliente && !this.currentCentro) {
      this.openEscolha();
    }

    this.getUser();
  }

  async getUser() {
    this.user = await this._user.obter(this.user.id);
  }

  getTotalQ() {
    let item = 0;
    this.carrinhos.forEach(element => {
      item += element.quantidade;
    });
    return item;
  }

  getTotal() {
    let item = 0;
    this.carrinhos.forEach(element => {
      item += element.valor * element.quantidade;
    });
    return item;
  }

  carAdd(item: number, product: ItemCarrinho) {
    if (product.quantidade === (product.cota ? product.cota.numero_total : 0)) return 
    const quantidade = product.quantidade;
    if (item + quantidade > -1) {
      let aux = { ...product };
      product.quantidade = item + quantidade;
      aux.quantidade = 1;
      this._carrinho.adicionar(aux, false);
    }
  }

  async removeItem(product: ItemCarrinho) {
    const quantidade = product.quantidade;
    if (quantidade == 1) {
      Swal.fire({
        title: `Remover esse item?`,
        text: 'Esta ação é irreversível!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apagar!',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this._carrinho.excluirItem(product);
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.getCountCart();
          localStorage.clear();
          Swal.fire({
            type: 'success',
            title: 'Apagado!',
            text: 'O produto foi excluído com sucesso.',
            timer: 1500
          });
        }
      });
    } else {
      await this._carrinho.excluirItem(product);
      product.quantidade -= 1;

      let aux = { ...product };

   
      this._carrinho.adicionar(aux, false);
    }
  }

  openEscolha() {
    const modal = this.modalService.open(EmpresaCentroCustoComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: "bg-empresa-centro-custo"
    });
    modal.componentInstance.modal = true;
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.openMobileMenu = !this.openMobileMenu;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  getCountCart() {
    this.loading = true;
    this._carrinho.obter().then(res => {
      this.loading = false;
      this.carrinhos = res;
      if (res.length) {
        document.getElementById('carrinhosSpan').removeAttribute('hidden');
        document.getElementById('carrinhosL').innerHTML = res.length.toString();
      }
    });
  }

  clearCart() {
    Swal.fire({
      title: `Apagar todos os itens?`,
      text: 'Esta ação é irreversível!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this._carrinho.excluir();
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.value) {
        document.getElementById('carrinhosL').innerHTML = '0';
        this.carrinhos = [];
        localStorage.clear();
        Swal.fire('Apagado!', 'O carrinho foi excluído com sucesso.', 'success');
      }
    });
  }
}
