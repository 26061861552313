import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Carrinho, ItemCarrinho } from '../models/carrinho';
import { ClienteService } from './cliente.service';
import { CentroCusto } from '../models/centro-custo';
import { CentroCustoService } from './centro-custo.service';

@Injectable({ providedIn: 'root' })
export class CarrinhoService {
  cliente: any;
  carrinho: Carrinho;

  constructor(
    private http: HttpClient,
    private _cliente: ClienteService,
    private _centro: CentroCustoService,
  ) { }

  obter(): Promise<ItemCarrinho[]> {

    let currentCentro = this._centro.current();
    let currentCliente = this._cliente.current();



    return this.http.get<ItemCarrinho[]>(`${environment.apiUrl}/carrinho`).toPromise().then(async (res: any) => {
      if (res) {
        this.carrinho = res;
        res.itens.forEach(element => {
          element.quantidade = Number.parseFloat(element.quantidade);
          // element.valor = Number.parseFloat(element.valor);
        });

        return this.http
          .post<Carrinho>(`${environment.apiUrl}/carrinho/buscar-produtos-atualizados?cliente_id=${currentCliente.id}&centro_custo_id=${currentCentro.id}`, res.itens.map(x => x.produto_id))
          .toPromise()
          .then((res1: any) => {
            let aux = [];
            for (let index = 0; index < res.itens.length; index++) {
              delete res.itens[index].valor;
              res1[index].valor = Number.parseFloat(res1[index].valor);
              aux.push({ ...res.itens[index], ...res1[index] });
            }
            return aux;
          });
      }
      return [];
    });



  }

  obterAtualizados(res: ItemCarrinho[], currentCentro: CentroCusto = this._centro.current()): Promise<ItemCarrinho[]> {
    res = [...res];
    let currentCliente = this._cliente.current();
    return this.http
      .post<Carrinho>(`${environment.apiUrl}/carrinho/buscar-produtos-atualizados?cliente_id=${currentCliente.id}&centro_custo_id=${currentCentro.id}`, res.map(x => x.produto_id))
      .toPromise()
      .then((res1: any) => {
        let aux = [];
        for (let index = 0; index < res.length; index++) {
          res1[index].valor = Number.parseFloat(res1[index].valor);
          aux.push({ ...res[index], ...res1[index] });
        }
        return aux;
      });
  }

  adicionar(item: ItemCarrinho, show = true) {
    if (show) {
      document.getElementById('carrinhosSpan').removeAttribute('hidden');
      document.getElementById('carrinhosL').innerHTML = (
        Number.parseInt(document.getElementById('carrinhosL').innerHTML, 10) + 1
      ).toString();
    }
    item.valor = Number.parseInt(item.valor.toString(), 10);
    return this.http.post<Carrinho>(`${environment.apiUrl}/carrinho/item/${this.carrinho.id}`, item).toPromise();
    // return this.http.post<Carrinho>(`${environment.apiUrl}/carrinho/item/` + item.produto_id, item).toPromise();
  }

  adicionarCarrinho(item: ItemCarrinho[]) {
    item.forEach(element => {
      element.valor = Number.parseInt(element.valor.toString(), 10);
    });
    document.getElementById('carrinhosSpan').removeAttribute('hidden');
    document.getElementById('carrinhosL').innerHTML = (Number.parseInt(document.getElementById('carrinhosL').innerHTML, 10) + 1).toString();

    let currentCentro = this._centro.current();
    let currentCliente = this._cliente.current();

    return this.http
      .post<Carrinho>(`${environment.apiUrl}/carrinho?cliente_id=${currentCliente.id}&centro_custo_id=${currentCentro.id}`, item)
      .toPromise()
      .then(res => {
        this.carrinho = new Carrinho();
        this.carrinho.id = res.id;
      });
    // return this.http.post<Carrinho>(`${environment.apiUrl}/carrinho/item/` + item.produto_id, item).toPromise();
  }

  excluir() {
    return this.http
      .delete(`${environment.apiUrl}/carrinho/${this.carrinho.id}`)
      .toPromise()
      .then(() => {
        this.carrinho = null;
        document.getElementById('carrinhosSpan').setAttribute('hidden', 'true');
        document.getElementById('carrinhosL').innerHTML = '0';
      });
  }

  excluirItem(item: ItemCarrinho) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        produto_id: item.produto_id.toString()
      }
    };

    return this.http
      .delete(`${environment.apiUrl}/carrinho/item/${this.carrinho.id}`, options)
      .toPromise()
      .then(() => { });
  }
}
