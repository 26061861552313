import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
/**
 * Error 404 component - handling the error-404
 */
export class Error404Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
